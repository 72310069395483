import * as React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'styles';
import AlertBox from 'react-app/components/AlertBox';
import Dialog from 'react-app/components/Dialog';
import HtmlParser from 'react-app/components/HtmlParser';
import Button from 'components/Button';
import PageContainer from 'components/PageContainer';
import { isExternalLink } from 'lib/helpers';
import { useNotify } from 'components/Notify';
import InfoIcon from '@mui/icons-material/Info';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import YouTubeIcon from '@mui/icons-material/YouTube';
import ScholIcon from '@mui/icons-material/School';
import WatchIcon from '@mui/icons-material/WatchLaterOutlined';
import PercentCircleIcon from 'react-app/icons/PercentCircle';

const icons = {
	Info: InfoIcon,
	OpenInNew: OpenInNewIcon,
	Shop: ShoppingCartIcon,
	YouTube: YouTubeIcon,
	School: ScholIcon,
	Percent: PercentCircleIcon,
	Watch: WatchIcon
};

function AppBanner(props) {
	const {
		classes: classesProp,
		className,
		text,
		id,
		href,
		type = 'info',
		icon,
		buttonIcon,
		buttonText = 'mehr erfahren',
		ButtonProps,
		dismissible = true,
		dialog,
		exludePages,
		...rest
	} = props;

	const theme = useTheme();

	const { appBannersSeen, setAppBannerSeen } = useNotify();
	const seen = appBannersSeen.includes(id);

	const [dialogOpen, setDialogOpen] = React.useState(false);

	function handleDialogOpen() {
		if (dialog) {
			setDialogOpen(true);
		}
	}

	function handleDialogClose() {
		setDialogOpen(false);
	}

	const handleClose = React.useCallback((event) => {
		setAppBannerSeen(id);
	}, [id, setAppBannerSeen]);

	const Icon = icons[icon];
	const ButtonIcon = icons[buttonIcon] || (isExternalLink(href) ? icons.OpenInNew : null);

	return (!seen ? (
		<PageContainer flexGrow={0} disableGutterBottom centered>
			<AlertBox
				id={id}
				noBorder
				fullWidth
				rounded={false}
				borderRadius={{
					xs: `0 0 ${theme.shape.borderRadius}px ${theme.shape.borderRadius}px`
				}}
				{...rest}
				type={type}
				icon={Icon ? <Icon fontSize="small"/> : undefined}
				{...(dismissible && {
					onClose: handleClose
				})}
				action={(href || dialog) ? (
					<Button
						size="small"
						color="inherit"
						{...ButtonProps}
						{...(dialog ? {
							onClick: handleDialogOpen
						} : {
							href
						})}
						startIcon={ButtonIcon ? <ButtonIcon/> : undefined}
					>
						{buttonText}
					</Button>
				) : null}
			>
				<HtmlParser sx={{'&&': {fontSize: '14px', linHeight: 1.2}}} html={text}/>
				{Boolean(dialog) && (
					<Dialog
						open={dialogOpen}
						onClose={handleDialogClose}
						title={dialog?.title}
					>
						<HtmlParser html={dialog?.html}/>
					</Dialog>
				)}
			</AlertBox>
		</PageContainer>
	) : null);
}

AppBanner.propTypes = {
	text: PropTypes.string.isRequired,
	id: PropTypes.string.isRequired,
	type: PropTypes.string.isRequired,
	icon: PropTypes.string,
	to: PropTypes.string,
	buttonText: PropTypes.string,
	buttonIcon: PropTypes.string,
	ButtonProps: PropTypes.object,
	dismissible: PropTypes.bool
};

export default React.memo(AppBanner);
